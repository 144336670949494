export class Vector3{
	public x: number;
	public y: number;
	public z: number;

	static New(x:number ,y:number ,z :number):Vector3 {
		let v = new Vector3();
		v.x=x;v.y=y;v.z=z;
		return v;
	}

	constructor(){
		this.x=this.y=this.z=0;
	}


}
