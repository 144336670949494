import { autoinject, bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { CustomiseViewService } from "./customize-view.service";
import { SessionsService } from "./sessions.service";
import { Sessionselector } from "./react-components/sessionselector";
import { Session } from "./model-session";
import { AuthService } from "./auth-service";
import { DialogService } from "aurelia-dialog";
import { ShareComponent } from "share-component";
import { EventAggregator } from "aurelia-event-aggregator";
import { ConfigsService } from "hke-configs.service";
import { PLATFORM } from "aurelia-pal";
import { DevMode } from "dev-mode";

@autoinject
export class NavBar {
  @bindable
  private router: Router;
  private sessions: Session[];

  username: string;
  usermail: string;
  hasSession: boolean;

  private homevenues: string = "";

  // Editor mode to modify values for dashboard
  inEdit = false;
  config: { [key: string]: any } = {};

  // TODO: Should be if jwt scope has dashboard-editor scope
  editor = true;

  onEditConfig(key: string) {
    this.ConfigsService.update(key, this.config[key]);
    this.inEdit = false;
  }

  constructor(
    private customiseViewService: CustomiseViewService,
    private sessionsService: SessionsService,
    private AuthService: AuthService,
    private DialogService: DialogService,
    private EventAggregator: EventAggregator,
    private ConfigsService: ConfigsService,
    private DevMode: DevMode
  ) {
    this.username = "";
    this.usermail = "";
    this.hasSession = this.sessionsService.HasSelectedSession();

    this.editor = DevMode.IsDev();
  }

  attached() {
    this.EventAggregator.subscribeOnce(
      ConfigsService.EventConfigReady,
      (config: { [key: string]: string }) => {
        this.config = config;
      }
    );

    this.EventAggregator.subscribe(
      SessionsService.EventSessionNameChanged,
      () => {
        this.hasSession = this.sessionsService.HasSelectedSession();
      }
    );

    this.sessionsService.listSessions().then((items) => {
      this.sessions = items;
    });

    this.usermail = this.AuthService.UserMail();
    this.username = this.AuthService.Username();

    this.homevenues = this.AuthService.HomeVenues().join(",");
  }

  onLogout() {
    this.AuthService.Logout();
  }

  onShowCustomise() {
    this.customiseViewService.ShowCustomize();
  }

  showSessionSelectorv2() {
    this.DialogService.open({
      viewModel: Sessionselector,
      view: PLATFORM.moduleName("./react-components/sessionselector.html"),
      model: {},
    });
  }

  updateSessionName() {
    this.sessionsService.ClearSessionStorageSession();
    this.sessionsService.UpdateSessionName();
  }

  async onShowShare() {
    await this.DialogService.closeAll();
    this.DialogService.open({
      viewModel: ShareComponent,
      view: PLATFORM.moduleName("./share-component.html"),
      model: { sessionname: this.sessionsService.restoreSession() },
    });
  }
}
