import { SessionsService } from "./sessions.service";

import { autoinject } from "aurelia-framework";
import { Session } from "./model-session";
import { DialogController } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";

@autoinject
export class OpenSession {
  private sessions: Session[];
  private sessionsSub: Subscription;

  constructor(
    private sessionsService: SessionsService,
    private dialogController: DialogController,
    private ea: EventAggregator
  ) {}

  attached() {
    this.sessionsSub = this.ea.subscribeOnce(
      SessionsService.EventSessionNameChanged,
      () => {
        this.dialogController.close(true);
      }
    );

    this.sessionsService.listSessions().then((items) => {
      this.sessions = items;
      console.log(this.sessions);
    });
  }

  detached() {
    this.sessionsSub.dispose();
  }

  onOpenSession() {
    this.sessionsService.UpdateSessionName();
  }
}
