import { SessionsService } from "sessions.service";
import { PLATFORM } from "aurelia-pal";
import { autoinject } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { OpenSession } from "open-session";

@autoinject
export class RootDashboard {
  constructor(
    private sessionsService: SessionsService,
    private dialogService: DialogService
  ) {}

  attached() {
    this.sessionsService.restoreSession();

    if (false == this.sessionsService.HasSelectedSession()) {
      this.dialogService.open({
        viewModel: OpenSession,
        view: PLATFORM.moduleName("./open-session.html"),
      });
    }
  }
}
