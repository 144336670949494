import { createTheme } from "@mui/material";

interface MyPaletteExtensions {
  custom: {
    active56p: string;
    grey3: string;
    diagramBg: string;
    progressIndicator: string;
    secondaryShades16p: string;
  };
}

declare module "@mui/material/styles" {
  interface Palette extends MyPaletteExtensions {}
  interface PaletteOptions extends MyPaletteExtensions {}
}

const pal = {
  common: {
    black: "#000",
    white: "#fff",
  },
  primary: {
    main: "#FF6600", // HE Orange
    light: "#e3f2fd",
    dark: "#42a5f5",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  secondary: {
    main: "#90caf9",
    light: "#e3f2fd",
    dark: "#42a5f5",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  error: {
    main: "#f44336",
    light: "#e57373",
    dark: "#d32f2f",
    contrastText: "#fff",
  },
  warning: {
    main: "#ffa726",
    light: "#ffb74d",
    dark: "#f57c00",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  info: {
    main: "#29b6f6",
    light: "#4fc3f7",
    dark: "#0288d1",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  success: {
    main: "#66bb6a",
    light: "#81c784",
    dark: "#388e3c",
    contrastText: "rgba(0, 0, 0, 0.87)",
  },
  grey: {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
    A100: "#f5f5f5",
    A200: "#eeeeee",
    A400: "#bdbdbd",
    A700: "#616161",
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
  },
  divider: "rgba(0, 0, 0, 0.12)", // MUI Light Divider
  background: {
    paper: "#121212",
    default: "#121212",
  },
  action: {
    active: "#fff",
    hover: "rgba(255, 255, 255, 0.08)",
    hoverOpacity: 0.08,
    selected: "rgba(255, 255, 255, 0.16)",
    selectedOpacity: 0.16,
    disabled: "rgba(255, 255, 255, 0.3)",
    disabledBackground: "rgba(255, 255, 255, 0.12)",
    disabledOpacity: 0.38,
    focus: "rgba(255, 255, 255, 0.12)",
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
  custom: {
    active56p: "rgba(255,255,255, 0.56)",
    grey3: "#828282",
    diagramBg: "#3a3a3a",
    progressIndicator: "#3d3d3d",
    secondaryShades16p: "rgb(144 202 249 / 16%)",
  },
};

const customBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

// Generate CSS variables from the theme colors
(() => {
  const root = document.documentElement;
  for (var i = 0; i < Object.keys(pal).length; i++) {
    let parent = Object.keys(pal)[i];
    // Skip non-color variables
    if (
      parent === "contrastThreshold" ||
      parent === "divider" ||
      parent === "tonalOffset"
    ) {
      continue;
    }
    //@ts-ignore
    for (var j = 0; j < Object.keys(pal[parent]).length; j++) {
      //@ts-ignore
      let child = Object.keys(pal[parent])[j];
      //@ts-ignore
      let childValue = Object.values(pal[parent])[j];
      root.style.setProperty(
        `--${parent}_${child}`,
        //@ts-ignore
        childValue
      );
    }
  }
})();

const defaultTheme = createTheme({ breakpoints: customBreakpoints }); // Need generated breakpoints for MuiContainer styleOverride

// DEFAULT DARK THEME
export const theme = createTheme({
  palette: { mode: "dark", ...pal },
  breakpoints: customBreakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& .MuiTouchRipple-root": {
            // Disable ripple effect
            display: "none",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: pal.text.secondary,
          "& .MuiSvgIcon-root svg": {
            fill: `${pal.text.secondary} !important`,
          },
          "&.Mui-selected": {
            color: pal.text.primary,
            "& .MuiSvgIcon-root svg": {
              fill: `${pal.text.primary} !important`,
            },
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up("lg")]: {
            maxWidth: customBreakpoints.values.lg,
          },
          [defaultTheme.breakpoints.up("xl")]: {
            maxWidth: customBreakpoints.values.xl,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root svg": {
            fill: `${pal.text.secondary} !important`,
          },
        },
      },
    },
  },
});
