import { CustomiseViewService } from "customize-view.service";
import { bindable, autoinject } from "aurelia-framework";
import { SessionsService } from "sessions.service";

import { DialogController } from "aurelia-dialog";

import copy from "copy-to-clipboard";
@autoinject
export class ShareComponent {
  readonlylink = "";
  copied = false;

  @bindable
  sessionname: string;

  constructor(
    private sessions: SessionsService,
    private customiseViewService: CustomiseViewService,
    private DialogController: DialogController
  ) {}

  attached() {
    this.sessionname = this.sessions.GetSessionName();
  }

  onClose() {
    this.DialogController.close(true);
  }

  onCopyLink() {
    copy(this.readonlylink);
    this.copied = true;
    window.setTimeout(() => {
      this.copied = false;
    }, 1000);
  }

  onGenerateReadonlyLink() {
    this.customiseViewService
      .GenerateReadonlyLink(this.sessions.GetSessionName())
      .then((link: string) => (this.readonlylink = link));
  }
}
