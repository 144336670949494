import { SessionsService } from "./sessions.service";
import { autoinject } from "aurelia-framework";
import { MessagingAPI } from "./messaging.service";
import { CustomiseViewService } from "customize-view.service";
import { RouteConfig, NavigationInstruction } from "aurelia-router";

@autoinject
export class Viewer {
  private sessionName: string;

  constructor(
    private sessionsService: SessionsService,
    private MessagingAPI: MessagingAPI,
    private CustomiseViewService: CustomiseViewService
  ) {}

  async activate(
    params: any,
    _: RouteConfig,
    _navInstruction: NavigationInstruction
  ) {
    this.sessionName = params.sessionname;
    this.sessionsService.SetSessionNameSession(this.sessionName);

    let response = await this.MessagingAPI.InitConnect(this.sessionName);

    if (response.meta) {
      if ("layout" in response.meta) {
        try {
          const layout = JSON.parse(response.meta["layout"]);
          this.CustomiseViewService.SaveLayout(layout);
        } catch (ex) {
          console.error("unable to decode layout from meta");
        }
      }
    }
  }
}
