import * as erd from "element-resize-detector";

export interface ResizedDetails {
  width: number;
  height: number;
  widthOld: number;
  heightOld: number;
}

export class ResizeableCustomAttribute {
  static inject = [Element];

  element: HTMLElement;
  callback;
  erd;

  constructor(element) {
    this.element = element;
    this.erd = erd({ strategy: "scroll" });
  }

  bind() {
    const element = this.element;
    var widthOld = element.offsetWidth;
    var heightOld = element.offsetHeight;

    this.callback = (x) => {
      var event = new CustomEvent("resize", {
        detail: {
          width: this.element.offsetWidth,
          height: this.element.offsetHeight,
          widthOld: widthOld,
          heightOld: heightOld,
        } as ResizedDetails,
      });

      this.element.dispatchEvent(event);
      widthOld = this.element.offsetWidth;
      heightOld = this.element.offsetHeight;
    };

    this.erd.listenTo(this.element, this.callback);
  }

  unbind() {
    if (this.callback) {
      this.erd.uninstall(this.element);
      this.callback = null;
    }
  }
}
