import { HttpClient } from "aurelia-http-client";
import { AuthService } from "./auth-service";

export interface SessionDetails {
  name: string;
  date: string;
  hometeamname: string;
  awayteamname: string;
}

export interface InitConnectRs {
  session: SessionDetails;
  metrics: string[];
  meta: any;
  link: string;
}

export class MessagingAPI {
  private Http: HttpClient;
  static inject = [AuthService];
  constructor(private AuthService: AuthService) {
    this.Http = new HttpClient();
  }
  async InitConnect(session: string): Promise<InitConnectRs> {
    let token = await this.AuthService.liveAccessToken();

    let builder = this.Http.createRequest("/api/messaging/i")
      .asPost()
      .withContent({ u: session });

    if (token != "") {
      builder = builder.withHeader("Authorization", "Bearer " + token);
    }

    let resp = await builder.send();

    if (
      resp.statusCode != 200 ||
      (resp.responseType != "application/json" && resp.responseType != "json")
    ) {
      console.error("response should be json");
      return null;
    }

    return JSON.parse(resp.response) as InitConnectRs;
  }
}
