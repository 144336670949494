// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../static/modal-back-cover.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-back{background:#121212;bottom:0;left:0;position:fixed;right:0;top:0}.modal-back-cover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover;bottom:0;left:0;position:absolute;right:0;top:0}", "",{"version":3,"sources":["webpack://./src/modal-back-cover.component.scss"],"names":[],"mappings":"AAAA,YACC,kBAAA,CAEM,QAAA,CAAS,MAAA,CADf,cAAA,CACsB,OAAA,CAAtB,KAID,CADA,kBACC,wDAAA,CACA,uBAAA,CACA,qBAAA,CAGM,QAAA,CAAS,MAAA,CADf,iBAAA,CACsB,OAAA,CAAtB,KAMD","sourcesContent":[".modal-back{\n\tbackground:#121212;\n\tposition:fixed;\n\ttop:0;bottom:0;left:0;right:0;\n}\n\n.modal-back-cover {\n\tbackground-image: url('../static/modal-back-cover.png');\n\tbackground-position:center;\n\tbackground-size: cover;\n\n\tposition:absolute;\n\ttop:0;bottom:0;left:0;right:0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
