import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { HttpClient } from "aurelia-http-client";
import { AuthService } from "./auth-service";
import { Session } from "./model-session";
import { AppConfig } from "./app-config";

@autoinject
export class SessionsService {
  private sessionName: string = "";
  private static KeySessionName = "SessionName";
  public static EventSessionNameChanged = "EventSessionNameChanged";

  private localStorage: Storage;
  private sessionStorage: Storage;

  constructor(
    private ea: EventAggregator,
    private HttpClient: HttpClient,
    private AuthService: AuthService,
    private AppConfig: AppConfig
  ) {
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
    this.restoreSession();
  }

  // return list of sessions available to display on selector
  async listSessions(): Promise<Session[]> {
    const resp = await this.HttpClient.createRequest(
      `${this.AppConfig.BaseHttp()}/api/messaging/sessions`
    )
      .asGet()
      .withHeader("Authorization", await this.AuthService.AuthHeader())
      .send();

    let items: Session[] = JSON.parse(resp.response).filter(
      (s: Session) => s.Name
    );
    return items;
  }

  restoreSession() {
    let sessionName = this.sessionStorage.getItem(
      SessionsService.KeySessionName
    );
    if (sessionName == null) {
      sessionName = this.localStorage.getItem(SessionsService.KeySessionName);
    }

    if (sessionName != null) {
      this.sessionName = sessionName;
    }
  }

  HasSelectedSession(): boolean {
    return this.sessionName != "" && this.sessionName != null;
  }

  GetSessionName(): string {
    return this.sessionName;
  }

  ClearSessionStorageSession() {
    this.sessionStorage.removeItem(SessionsService.KeySessionName);
  }

  SetSessionNameSession(sessionName: string) {
    this.sessionName = sessionName;
    this.sessionStorage.setItem(
      SessionsService.KeySessionName,
      this.sessionName
    );
    this.ea.publish(SessionsService.EventSessionNameChanged);
  }

  UpdateSessionName() {
    this.localStorage.setItem(SessionsService.KeySessionName, this.sessionName);
    this.ea.publish(SessionsService.EventSessionNameChanged);
  }
}
