export type ValueConvertorFunction = (value: MetricValue) => string;

export class IndicatorStatus {
  val: number = 0;
  state: string = "";
}

export class MetricUnitDef {
  public suffix: string;
  public prefix: string;
  public mult: number;
  public placeholder: string;
}

export class MetricIndicator {
  static DefaultState = "def";

  public color: string;
  public displayName: string;
  public group: string;
  public index: number;
  public icon: string;
  public key: string;
  public units: MetricUnitDef[];
  public selectedUnit: MetricUnitDef;
  public states: IndicatorStatus[] = [];

  public valueConvertorFn: ValueConvertorFunction;
  multiplier: number;

  stateForValue(value: number): string {
    let result = MetricIndicator.DefaultState;
    if (typeof this.multiplier === "number") {
      value = value * this.multiplier;
    }
    this.states.forEach((el) => {
      if (value > el.val) {
        result = el.state;
      }
    });
    return result;
  }
}

export class MetricValue {
  public name: string;
  public value: any;
  public displayValue?: string;
  public state: string = "";
}

export function NewMetric(
  key: string,
  name: string,
  color: string,
  group: string,
  valueConvertorFn: ValueConvertorFunction,
  icon: string,
  units: MetricUnitDef[],
  states: IndicatorStatus[] = [],
  multiplier: number
): MetricIndicator {
  let mi = new MetricIndicator();
  mi.color = color;
  mi.key = key;
  mi.displayName = name;
  mi.group = group;

  mi.icon = icon;
  mi.units = units;

  mi.multiplier = multiplier;

  if (states) {
    mi.states = states;
  }

  mi.valueConvertorFn = valueConvertorFn;

  return mi;
}
