import React from "react";
import ReactDOM from "react-dom";
import { autoinject } from "aurelia-framework";

import { SessionsService } from "../sessions.service";

import { AuthService } from "../auth-service";

import { useState, useEffect } from "react";
import { Location, Team, Session } from "../model-session";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Paper,
  Box,
  Button,
  Checkbox,
  Typography,
  SelectChangeEvent,
} from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import ShareIcon from "@mui/icons-material/Share";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import FormControlLabel from "@mui/material/FormControlLabel";

import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";

@autoinject()
export class Sessionselector {
  reactComponent: ReactDOM.RootRender = {};

  constructor(
    private element: Element,
    private SessionsService: SessionsService,
    private AuthService: AuthService
  ) {
    this.element = element;
  }

  async render() {
    const sessions = await this.SessionsService.listSessions();

    this.reactComponent = ReactDOM.render(
      <HESessionSelector
        sessions={sessions}
        onSelectCallback={(session: Session) => {
          this.SessionsService.SetSessionNameSession(session.TablePrefix);
        }}
        homeVenues={this.AuthService.HomeVenues()}
      />,
      this.element
    );
  }

  bind() {
    this.render();
  }
}

const gameplayColors = {
  Network: "#4CAF50;",
  Away: "#CE93D8",
  Home: "#90CAF9",
};

interface HESessionSelectorArgs {
  sessions: Session[];
  homeVenues: string[];
  onSelectCallback: (session: Session) => void;
}

interface HESessionSessionRow {
  title: string;
  subtitle: string;
  playtype: string;
  Session: Session;
}

export const HESessionSelector = ({
  sessions,
  homeVenues,
  onSelectCallback,
}: HESessionSelectorArgs) => {
  const venuesMap: { [key: string]: Location } = {};
  const teamsMap: { [key: string]: Team } = {};
  const typesMap: { [key: string]: boolean } = {};

  const data = sessions.map((el: Session): HESessionSessionRow => {
    if (el.Location) {
      if (!el.Location.DisplayName) {
        el.Location.DisplayName = "Not specified";
      }

      if (!el.Location.HEID) {
        el.Location.HEID = "Unknown - " + el.Location.DisplayName;
      }
      venuesMap[el.Location.HEID] = el.Location;
    }

    if (el.TeamHome) {
      teamsMap[el.TeamHome.Code] = el.TeamHome;
    }

    if (el.TeamAway) {
      teamsMap[el.TeamAway.Code] = el.TeamAway;
    }

    if (el.Playtype) {
      typesMap[el.Playtype] = true;
    }

    return {
      Session: el,
      title: (el.Playtype ? el.Playtype + " " : "") + el.Name,
      subtitle: new Date(el.Timestamp).toLocaleString(),
      playtype: homeVenues.indexOf(el.Location.HEID) >= 0 ? "Home" : "Away",
    };
  });

  const [filterVenue, setFilterVenue] = useState("all");
  const [filterType, setFilterType] = useState("all");
  const [filterTeam, setFilterTeam] = useState("all");

  const [filteredSessions, setFilteredSessions] = useState<
    HESessionSessionRow[]
  >([]);

  const onLoadSession = (session: Session) => {
    if (onSelectCallback) {
      onSelectCallback(session);
    }
  };

  useEffect(() => {
    if (data == null) {
      setFilteredSessions([]);
      return;
    }
    const filteredData = data.filter((el: HESessionSessionRow) => {
      const s = el.Session;
      return (
        (s.Location.HEID === filterVenue || filterVenue === "all") &&
        ((s.TeamHome && s.TeamHome.Code === filterTeam) ||
          (s.TeamAway && s.TeamAway.Code === filterTeam) ||
          filterTeam === "all") &&
        (s.Playtype === filterType || filterType === "all")
      );
    });

    setFilteredSessions(filteredData);

    return () => {};
  }, [filterVenue, filterTeam, filterType]);

  const [selectedSession, setSelectedSession] = useState<Session | null>(null);

  const onSelectSession = (session: Session) => {
    setSelectedSession(session);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Paper sx={{ padding: "20px" }}>
          <Typography variant="h5">Session Selector</Typography>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <FormControl sx={{ flex: 1 }} variant="standard">
              <InputLabel>Venues</InputLabel>
              <Select
                onChange={(event: SelectChangeEvent) => {
                  setFilterVenue(event.target.value);
                }}
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {Object.keys(venuesMap).map((code: string) => (
                  <MenuItem key={code} value={code}>
                    {venuesMap[code].DisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ flex: 1 }} variant="standard">
              <InputLabel>Team</InputLabel>
              <Select>
                <MenuItem value="all" key="all">
                  All
                </MenuItem>

                {Object.keys(teamsMap).map((code: string) => (
                  <MenuItem key={code} value={code}>
                    {teamsMap[code].DisplayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ flex: 1 }} variant="standard">
              <InputLabel>Type</InputLabel>
              <Select>
                <MenuItem>All</MenuItem>
                {Object.keys(typesMap).map((code: string) => (
                  <MenuItem value={code}>{code}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ flex: 1 }} />
          </Box>

          <div>
            <FormControlLabel control={<Checkbox />} label="Home games" />
            <FormControlLabel control={<Checkbox />} label="Away games" />
            <FormControlLabel control={<Checkbox />} label="Network games" />
          </div>

          <Box>
            <Paper>
              <TableContainer>
                <Table>
                  <TableBody>
                    {filteredSessions.map((el) => {
                      return (
                        <TableRow
                          sx={{ padding: "10px" }}
                          hover
                          selected={selectedSession === el.Session}
                          onClick={() => {
                            onSelectSession(el.Session);
                          }}
                        >
                          <TableCell>
                            <Typography variant="body1">{el.title}</Typography>
                            <Typography variant="body2" sx={{ opacity: 0.7 }}>
                              {el.subtitle}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            <Chip
                              sx={{ color: gameplayColors[el.playtype] }}
                              variant="outlined"
                              label={el.playtype}
                            />

                            {el.playtype === "Home" ? (
                              <ShareIcon
                                fontSize="large"
                                sx={{
                                  color: "#90CAF9",
                                  paddingLeft: "10px",
                                }}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>

          <div>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              disabled={selectedSession == null}
              onClick={() => {
                onLoadSession(selectedSession);
              }}
            >
              Load Selected Session <ArrowForwardIcon fontSize="large" />
            </Button>
          </div>
        </Paper>
      </ThemeProvider>
    </div>
  );
};
