import { bindable, observable } from "aurelia-framework";
import { Vector3 } from "./vector3";
import { MetricDef } from "./baseball.service";

class Vector2 {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

class Rect {
  left: number = 0;
  right: number = 0;
  top: number = 0;
  bottom: number = 0;

  width: number = 0;
  height: number = 0;

  constructor(top: number, right: number, bottom: number, left: number) {
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
    this.width = this.right - this.left;

    this.height = this.bottom - this.top;
  }
}

interface RectS {
  left: string;
  right: string;
  top: string;
  bottom: string;
}

// dimensions specified like "1.33inch" or "1m"
interface ZoneDefinition {
  width: string;
  tall: string;
  above_ground: string;

  // frontfacing defines if we are looking at strike zone from pitcher perspective
  frontfacing: boolean;

  shadow: RectS;
  waste: RectS;
}

export interface PitchGraphConfig {
  title: string;
  axis: number[];
  dimensions: ZoneDefinition | null;
}

// Parses "1inch" or "1m" to meters
function parseDimensionToMeters(dim: string): number {
  if (dim.endsWith("inch")) {
    return parseFloat(dim.replace("inch", "")) * 0.0254;
  }

  if (dim.endsWith("m")) {
    return parseFloat(dim.replace("m", ""));
  }

  try {
    return parseFloat(dim);
  } catch {
    console.error("unable to parse dimension to meters");
  }

  return 0;
}

export class PitchGraphComponent {
  private canvasid: string;
  @bindable values: Vector3[] = [];
  @bindable activeIndex: number = 0;
  @bindable lastvalueonly: boolean = false;

  @bindable views: PitchGraphConfig[] = [];
  private currentviewindex: number = 0;

  @bindable mapping: number[] = [1.19632, 0.4445, 0.46456, -0.4445];

  shadow: Rect = null;
  waste: Rect = null;

  @bindable widgetdef: MetricDef = null;
  private subtitle: string = "Batter view (Right hand batter)";

  private axis: Rect = new Rect(1, 1, -1, -1);

  private ctx2d: CanvasRenderingContext2D;

  private canvas: HTMLCanvasElement = null;
  @observable scale: number = 60;

  private lineColor = "white";

  constructor() {
    this.canvasid = "shouldberandom";
  }

  onnextview() {
    const newviewindex = (this.currentviewindex + 1) % this.views.length;

    if (newviewindex == this.currentviewindex) {
      return;
    }

    this.currentviewindex = newviewindex;
    this.updateCurrentViewIndex();
    this.mappingChanged(this.mapping, null);

    this.redraw();
  }

  scaleChanged(newValue: number) {
    this.redraw();
  }

  widgetdefChanged(newValue: MetricDef) {
    if ("views" in newValue.widget_params) {
      this.views = newValue.widget_params["views"];

      this.currentviewindex = 0;

      this.updateCurrentViewIndex();
    } else {
      this.views = [newValue.widget_params];

      this.mapping = newValue.widget_params.axis;
      this.subtitle = newValue.widget_params.title;
      this.shadow = null;
      this.waste = null;
    }

    // shadow zones:
    // if not defined, then use 1/9 of width/heigth
    if (this.shadow == null) {
      this.shadow = new Rect(
        this.axis.height / 9,
        this.axis.width / 9,
        this.axis.height / 9,
        this.axis.width / 9
      );
    }
  }

  updateCurrentViewIndex() {
    if (this.views[this.currentviewindex].dimensions) {
      const dim = this.views[this.currentviewindex].dimensions;
      // Generate mapping from ZoneDefinition
      // above_ground + tall, -half width, above_ground, half width
      const above = parseDimensionToMeters(dim.above_ground);
      const tall = parseDimensionToMeters(dim.tall);
      const width = parseDimensionToMeters(dim.width);

      var halfWidth = width / 2;

      if (dim.frontfacing == false) {
        halfWidth = -halfWidth;
      }

      this.mapping = [above + tall, -halfWidth, above, halfWidth];

      this.shadow = new Rect(
        dim.shadow.top ? parseDimensionToMeters(dim.shadow.top) : tall / 9,
        dim.shadow.right ? parseDimensionToMeters(dim.shadow.right) : width / 9,
        dim.shadow.bottom
          ? parseDimensionToMeters(dim.shadow.bottom)
          : tall / 9,
        dim.shadow.left ? parseDimensionToMeters(dim.shadow.left) : width / 9
      );

      if (dim.waste) {
        this.waste = new Rect(
          dim.waste.top ? parseDimensionToMeters(dim.waste.top) : 0.1,
          dim.waste.right ? parseDimensionToMeters(dim.waste.right) : 0.1,
          dim.waste.bottom ? parseDimensionToMeters(dim.waste.bottom) : 0.1,
          dim.waste.left ? parseDimensionToMeters(dim.waste.left) : 0.1
        );
      }
    } else {
      this.mapping = this.views[this.currentviewindex].axis;
      this.shadow = new Rect(
        this.axis.height / 9,
        this.axis.width / 9,
        this.axis.height / 9,
        this.axis.width / 9
      );

      this.waste = null;
    }

    this.subtitle = this.views[this.currentviewindex].title;
  }

  mappingChanged(newValue: number[], _: number[]) {
    if (
      "undefined" === typeof newValue ||
      null == newValue ||
      Array.isArray(newValue) == false ||
      newValue.length != 4
    ) {
      this.mapping = [1.19632, 0.4445 / 2, 0.46456, -0.4445 / 2];
      this.shadow = null;
    }
    this.axis = new Rect(
      this.mapping[0],
      this.mapping[1],
      this.mapping[2],
      this.mapping[3]
    );
  }

  attached() {
    this.canvas = document.querySelector("#" + this.canvasid);
    this.ctx2d = this.canvas.getContext("2d");

    this.lineColor = getComputedStyle(this.canvas).getPropertyValue(
      "--foreground-color"
    );

    this.axis = new Rect(
      this.mapping[0],
      this.mapping[1],
      this.mapping[2],
      this.mapping[3]
    ); //Vector3.New(0.4445 * 2, 1.119632 - 0.480568, 10);

    this.redraw();
  }

  lastvalueonlyChanged() {
    this.redraw();
  }

  valuesChanged(_new: Vector3[], _old: Vector3[]) {
    if (this.canvas == null) {
      return;
    }

    this.redraw();
  }

  redraw() {
    const ctx = this.ctx2d;
    if (ctx == null || typeof ctx === "undefined") {
      return;
    }

    try {
      const scale = this.scale / 100.0;
      const radius = 10.0 * scale; //this.value.z * this.axisScale.z;

      let wasteMeters = new Rect(0.1, 0.1, 0.1, 0.1);

      if (this.waste != null) {
        wasteMeters = this.waste;
      }

      const mtopx = (m: Vector2): Vector2 => {
        let mleft =
          this.axis.left +
          (this.shadow.left + wasteMeters.left) * Math.sign(this.axis.left); //* (1.0 / scale);
        let mright =
          this.axis.right +
          (this.shadow.right + wasteMeters.right) * Math.sign(this.axis.right); //* (1.0 / scale);
        const mtop = this.axis.top + this.shadow.top + wasteMeters.top; //* (1.0 / scale);
        const mbottom =
          this.axis.bottom - this.shadow.bottom - wasteMeters.bottom; //* (1.0 / scale);

        const pxinm = this.canvas.width / Math.abs(mright - mleft);
        const pyinm = this.canvas.height / Math.abs(mbottom - mtop);

        const inchToPx = Math.min(pxinm, pyinm);

        mright *= pxinm / inchToPx;
        mleft *= pxinm / inchToPx;

        const x =
          //  (right - left) * // size on screen
          (m.x - mleft) / (mright - mleft); //remap from -0.4445 -x- 0.4445 to 0..1
        const y = (m.y - mtop) / (mbottom - mtop); // top + (bottom - top) * //0.5 * (bottom + top) + this.axisScale.y * this.value.y;

        return new Vector2(x * this.canvas.width, y * this.canvas.height);
      };

      // Clear
      ctx.fillStyle = "gray";
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      //     ctx.rect(0, 0, this.canvas.width, this.canvas.height);
      //    ctx.fill();

      const topleftS = mtopx(
        new Vector2(
          this.axis.left + this.shadow.left * Math.sign(this.axis.left),
          this.axis.top + this.shadow.top * Math.sign(this.axis.top)
        )
      );
      const bottomrightS = mtopx(
        new Vector2(
          this.axis.right + this.shadow.right * Math.sign(this.axis.right),
          this.axis.bottom - this.shadow.bottom * Math.sign(this.axis.bottom)
        )
      );

      const topLeftWaste = mtopx(
        new Vector2(
          this.axis.left +
            (this.shadow.left + wasteMeters.left) * Math.sign(this.axis.left),
          this.axis.top +
            (this.shadow.top + wasteMeters.top) * Math.sign(this.axis.top)
        )
      );
      const bottomRightWaste = mtopx(
        new Vector2(
          this.axis.right +
            (this.shadow.right + wasteMeters.right) *
              Math.sign(this.axis.right),
          this.axis.bottom -
            (this.shadow.bottom + wasteMeters.bottom) *
              Math.sign(this.axis.bottom)
        )
      );
      /*
      ctx.beginPath();
      ctx.strokeStyle = "orange";
      ctx.rect(
        topLeftWaste.x,
        topLeftWaste.y,
        bottomRightWaste.x - topLeftWaste.x,
        bottomRightWaste.y - topLeftWaste.y
      );
      ctx.stroke();
*/
      ctx.strokeStyle = this.lineColor; //`rgba(255,255,255,${gradAlpha * 1.5})`;

      const topleft = mtopx(new Vector2(this.axis.left, this.axis.top));
      const bottomright = mtopx(new Vector2(this.axis.right, this.axis.bottom));
      ctx.beginPath();
      // ctx.rect(left, top, w, bottom - top);
      ctx.rect(
        topleft.x,
        topleft.y,
        bottomright.x - topleft.x,
        bottomright.y - topleft.y
      );

      ctx.rect(
        topleftS.x,
        topleftS.y,
        bottomrightS.x - topleftS.x,
        bottomrightS.y - topleftS.y
      );
      ctx.stroke();

      ctx.beginPath();

      const left = topleft.x;
      const right = bottomright.x;
      const top = topleft.y;
      const bottom = bottomright.y;

      ctx.moveTo((left + right) / 2, topleftS.y);
      ctx.lineTo((left + right) / 2, topleft.y);

      ctx.moveTo((left + right) / 2, bottomright.y);
      ctx.lineTo((left + right) / 2, bottomrightS.y);

      ctx.moveTo(topleftS.x, (top + bottom) / 2);
      ctx.lineTo(topleft.x, (top + bottom) / 2);

      ctx.moveTo(bottomrightS.x, (top + bottom) / 2);
      ctx.lineTo(bottomright.x, (top + bottom) / 2);
      ctx.stroke();

      // Grid
      ctx.strokeStyle = this.lineColor; //`rgba(255,255,255,${gradAlpha * 1.5})`;
      ctx.beginPath();

      const w3 = (bottomright.x - topleft.x) / 3;
      const h3 = (bottomright.y - topleft.y) / 3;
      ctx.moveTo(left + w3, topleft.y);
      ctx.lineTo(left + w3, bottomright.y);
      ctx.moveTo(left + 2 * w3, topleft.y);
      ctx.lineTo(left + 2 * w3, bottomright.y);

      ctx.moveTo(left, top + h3);
      ctx.lineTo(right, top + h3);
      ctx.moveTo(left, top + 2 * h3);
      ctx.lineTo(right, top + 2 * h3);

      ctx.stroke();

      // Spot

      // Drawing spot at specific location.
      // modification of context done outside of this call
      let drawSpot = (value: Vector3) => {
        if (value.x != 0 || value.y != 0) {
          const center = mtopx(value);

          if (
            center.x < topLeftWaste.x ||
            center.x > bottomRightWaste.x ||
            center.y < topLeftWaste.y ||
            center.y > bottomRightWaste.y
          ) {
            return;
          }

          ctx.beginPath();
          ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
          ctx.fill();
        }
      };

      // set color for non-active spots
      ctx.fillStyle = "#1976D280";
      this.values.forEach((value, index, _) => {
        if (this.lastvalueonly && index != this.activeIndex) {
          return;
        }

        drawSpot(value);
      });

      // Draw active spot
      if (this.activeIndex >= 0 && this.activeIndex < this.values.length) {
        ctx.fillStyle = "#FF6600";
        drawSpot(this.values[this.activeIndex]);
      }
    } catch (ex) {
      console.error(ex);
    }
  }
}
